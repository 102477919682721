<template>
    <el-main>

        <el-card>
            <div class='app-card center'>
                <el-space style='height: 100%;'>
                    <span>{{user.name}}</span>
                    <h1> 欢迎回来!!!</h1>
                </el-space>
            </div>
        </el-card>

    </el-main>
</template>
<style>
    .app-card {
        font-size: 16px;
        width: 240px;
        height: 120px;
        color: #8e8e8e;
        text-align: left;
        position: relative;
    }

    .center {
        text-align: center;
    }
</style>
<script>
    import { ref, reactive } from 'vue'
    import { getUserInfo } from '@/utils/tools'


    export default {
        setup: function () {

            return {
                user: ref(getUserInfo())
            }
        },
        mounted: function () {
        }
    }
</script>